const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let office = urlParams.get('office') || 'oslo';

const API_URL = 'https://api.eggsdesign.com/events/kiosk/';

const DEFAULT_DATA = {
  urls: [
    'https://events.eggsdesign.com/?habitat=oslo',
    'https://eggsdesign.github.io/eggs-active-challenge',
  ],
  kioskInterval: 30,
}

function fetchKioskData(office) {
  return fetch(`${API_URL}${office}`).then((response) => {
    return response.json();
  })
}

fetchKioskData(office).then((data) => {
  let index = 0;

  /**
   * Deal with missing urls from API
   */
  if (!data.urls || data.urls.length === 0) {
    data.urls = DEFAULT_DATA.urls;
  }

  /**
   * Deal with missing interval from API
   */
  if (!data.kioskInterval || data.kioskInterval === 0) {
    data.kioskInterval = DEFAULT_DATA.kioskInterval;
  }

  /**
   * Only start "slideshow" if more than one (1) url item
   */
  if (data.urls.length > 1) {
    setTimeout(() => {
      document.getElementById('contentFrame').setAttribute('src', data.urls[index]);
    }, 2000)

    // noinspection JSUnresolvedVariable
    setInterval(() => {
      if (index < data.urls.length - 1) {
        index += 1;
      } else {
        index = 0;
      }
      document.getElementById('contentFrame').setAttribute('src', data.urls[index]);
    }, data.kioskInterval * 1000);
  } else {
    document.getElementById('contentFrame').setAttribute('src', data.urls[0]);
  }
});


